:root {
  --primary-100: #ec948d;
  --primary-300: #e7756c;
  --primary-500: #e2574c;
  --primary-600: #cb4e44;
  --primary-700: #a74038;
  --primary-900: #6f2b26;

  --secondary-100: #c5d8df;
  --secondary-200: #a4c1cc;
  --secondary-300: #6598ab;
  --secondary-400: #528ba0;
  --secondary-500: #3f7e96;
  --secondary-600: #2c728c;
  --secondary-800: #255e73;

  --accent-100: #faefd3;
  --accent-300: #f1d17b;
  --accent-500: #efc75e;

  --grey-100: #cecfd1;
  --grey-300: #b7b8ba;
  --grey-500: #898a8c;
  --grey-700: #5c5c5d;
  --grey-900: #2e2e2f;

  --white: #fcfdff;
  --black: #171718;

  --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --monospace: Inconsolata, "Courier New", Courier, monospace;
  --sans-serif: -apple-system, BlinkMacSystemFont, "Segoe WPC", "Segoe UI", Ubuntu, "Droid Sans",
    sans-serif;

  --ion-color-primary: #e2574c;
  --ion-color-primary-rgb: 226, 87, 76;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #c74d43;
  --ion-color-primary-tint: #e5685e;

  --ion-color-secondary: #528ba0;
  --ion-color-secondary-rgb: 82, 139, 160;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #487a8d;
  --ion-color-secondary-tint: #6397aa;

  --ion-color-tertiary: #efc75e;
  --ion-color-tertiary-rgb: 239, 199, 94;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #d2af53;
  --ion-color-tertiary-tint: #f1cd6e;

  --ion-color-success: #71ce73;
  --ion-color-success-rgb: 113, 206, 115;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #63b565;
  --ion-color-success-tint: #7fd381;

  --ion-color-warning: #efc75e;
  --ion-color-warning-rgb: 239, 199, 94;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d2af53;
  --ion-color-warning-tint: #f1cd6e;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #2e2e2f;
  --ion-color-dark-rgb: 46, 46, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #282829;
  --ion-color-dark-tint: #434344;

  --ion-color-medium: #b7b8ba;
  --ion-color-medium-rgb: 183, 184, 186;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #a1a2a4;
  --ion-color-medium-tint: #bebfc1;

  --ion-color-light: #fcfdff;
  --ion-color-light-rgb: 252, 253, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dedfe0;
  --ion-color-light-tint: #fcfdff;
}
.bookmarker-menu-sidebar {
  background: linear-gradient(-77deg, var(--secondary-800), var(--secondary-600));
}
.box-shadow {
  box-shadow: var(--box-shadow);
}
.monospace {
  font-family: var(--monospace);
  word-wrap: break-word;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
html {
  background: linear-gradient(-13deg, #efc75e 10%, #e2574c 75%);
}
body {
  margin: 0;
  font-family: "Slabo 27px", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --ion-background-color: transparent;
  --ion-text-color: var(--white);
}
ion-action-sheet {
  --ion-background-color: var(--white);
  /* --ion-text-color: var(--black); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
  color: var(--white);
  font-family: var(--sans-serif);
}
